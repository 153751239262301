import { useCallback, useMemo } from 'react';

import { PhoneNumberForm } from 'common/components/forms/phone-number';
import { PageHeader } from 'common/components/page-header';
import { Routes } from 'common/router/constants';
import { ContactsSetPhoneNumberEndpoint } from 'endpoints/profile/contacts/set-phone';
import { showCode } from 'entities/common/show-code';

type FormFields = {
  phone: string;
};

type EnterPhoneStepProps = {
  currentPhone?: string;
  onSuccess: (phoneNumber: string) => void;
};

const EnterPhoneStep = ({ currentPhone, onSuccess }: EnterPhoneStepProps) => {
  const initialValues = useMemo(() => ({ phone: currentPhone ?? '' }), [currentPhone]);
  const trySubmit = useCallback(
    async (values: FormFields) => {
      const res = await ContactsSetPhoneNumberEndpoint.request({ phoneNumber: values.phone });
      showCode(res);
      onSuccess(values.phone);
    },
    [onSuccess],
  );

  return (
    <div>
      <PageHeader
        title="Phone Number"
        defaultBackRoute={Routes.ProfileContacts}
        enableMarginBottom
      />
      <PhoneNumberForm
        initialValues={initialValues}
        onSubmit={trySubmit}
      />
    </div>
  );
};

export type { EnterPhoneStepProps };
export { EnterPhoneStep };
