import { useCallback } from 'react';

import CodeForm from 'common/components/forms/code';
import { PageHeader } from 'common/components/page-header';
import { PHONE_CODE_FORM_AUGMENTATION } from 'common/containers/password-recovery/constants';
import { considerRequestTimer } from 'common/helpers/consider-request-timer';
import { Routes } from 'common/router/constants';
import { ContactsConfirmPhoneNumberEndpoint } from 'endpoints/profile/contacts/confirm-phone';
import { ContactsSetPhoneNumberEndpoint } from 'endpoints/profile/contacts/set-phone';

type EnterVerificationCodeStepProps = {
  phoneNumber: string;
  onSuccess: () => void;
  onCancel: () => void;
};

const EnterVerificationCodeStep = ({ onSuccess, onCancel, phoneNumber }: EnterVerificationCodeStepProps) => {
  const trySubmit = useCallback(
    async (code: string) => {
      await ContactsConfirmPhoneNumberEndpoint.request({ code });
      onSuccess();
    },
    [onSuccess],
  );

  const resendCode = useCallback(async () => {
    return considerRequestTimer(ContactsSetPhoneNumberEndpoint.request, { params: { phoneNumber } });
  }, [phoneNumber]);

  return (
    <div>
      <PageHeader
        title="Code from SMS"
        defaultBackRoute={Routes.ProfileContacts}
        enableMarginBottom
        onClickBack={onCancel}
      />
      <CodeForm
        {...PHONE_CODE_FORM_AUGMENTATION(phoneNumber)}
        illustration={undefined}
        onSubmit={trySubmit}
        onResendCode={resendCode}
      />
    </div>
  );
};

export type { EnterVerificationCodeStepProps };
export { EnterVerificationCodeStep };
