import createModernEndpoint from '@swe/shared/network/endpoint-factories/modern';

type Params = {
  phoneNumber: string;
};
type Data = {
  success: boolean;
  code: string;
  nextTryInSeconds: number;
};

const NAME = '/Account/SetPhoneNumber';
const ContactsSetPhoneNumberEndpoint = createModernEndpoint<Params, Data>(NAME);

export default ContactsSetPhoneNumberEndpoint;
export { ContactsSetPhoneNumberEndpoint };
