import { ApplicationPage } from 'app/types';
import { Page } from 'common/components/page';
import { DEFAULT_AUTHORIZED_PAGE_PROPS } from 'common/components/page/config';
import { UpdatePhone } from 'domains/profile/containers/edit-phone';

const TITLE = 'Update Phone';

const ProfileUpdatePhonePage: ApplicationPage = () => {
  return (
    <Page {...DEFAULT_AUTHORIZED_PAGE_PROPS}>
      <UpdatePhone />
    </Page>
  );
};

ProfileUpdatePhonePage.getMeta = () => ({ title: TITLE });

ProfileUpdatePhonePage.getMainLayoutProps = () => ({
  size: 'md',
  centered: 'x',
});

export default ProfileUpdatePhonePage;
