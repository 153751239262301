import Loader from '@swe/shared/ui-kit/components/loader';

import { useCallback, useState } from 'react';

import { useCurrentUser } from 'common/providers/user';
import { useRouterNavigate } from 'common/router';
import { Routes } from 'common/router/constants';

import { EnterPhoneStep } from 'domains/profile/containers/edit-phone/step/enter-phone';
import { EnterVerificationCodeStep } from 'domains/profile/containers/edit-phone/step/enter-verification-code';

enum Step {
  EnterPhone,
  EnterVerificationCode,
}

const UpdatePhone = () => {
  const navigate = useRouterNavigate();
  const [step, setStep] = useState<Step>(Step.EnterPhone);
  const { user, revalidate } = useCurrentUser();
  const [phone, setPhone] = useState('');

  const toVerificationCode = useCallback((phone: string) => {
    setPhone(phone);
    setStep(Step.EnterVerificationCode);
  }, []);
  const toEnterPhone = useCallback(() => {
    setStep(Step.EnterPhone);
  }, []);
  const handleSuccess = useCallback(async () => {
    await revalidate();
    return navigate(Routes.ProfileContacts, { replace: true });
  }, [revalidate, navigate]);

  if (!user) {
    return (
      <Loader
        size="lg"
        centered
      />
    );
  }

  switch (step) {
    case Step.EnterPhone:
      return (
        <EnterPhoneStep
          currentPhone={user.phoneNumber}
          onSuccess={toVerificationCode}
        />
      );
    case Step.EnterVerificationCode:
      return (
        <EnterVerificationCodeStep
          phoneNumber={phone}
          onSuccess={handleSuccess}
          onCancel={toEnterPhone}
        />
      );
  }
};

export { UpdatePhone };
